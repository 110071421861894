import React from "react";
import "./Attendence.css";
import Attendance from '../../Assets/attendance.png'
const Attendence = () => {
  return (
    <div className="container-main">
      <h2 className="h2">Leave Balance</h2>
      <div className="cards-row ">
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title-box">
            <div className="image-card">
              <img src={Attendance} alt="" className="card-image" />
            </div>
            <div style={{ paddingLeft: 10, paddingTop: 10 }}>
              <h3 className="h3">Paid Leavs</h3>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Attendence;
