import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaHome } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoLogOut } from "react-icons/io5";
import Logo from "../Assets/logo.png";
import Attendance from "../Assets/attendance.png";
import { useAuth } from "../Component/Authentication/AuthContext";

const AdminSlider = () => {
  const isTabletMid = useMediaQuery({ query: "(max-width: 1024px)" });
  const [open, setOpen] = useState(!isTabletMid);
  const [showDialog, setShowDialog] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    setOpen(!isTabletMid);
  }, [isTabletMid]);

  useEffect(() => {
    if (isTabletMid) setOpen(false);
  }, [pathname, isTabletMid]);

  const Nav_animation = {
    open: {
      width: "16rem",
      transition: {
        damping: 40,
      },
    },
    closed: {
      width: "4rem",
      transition: {
        damping: 40,
      },
    },
  };

  return (
    <div>
      {/* <div
        onClick={() => setOpen(false)}
        className={`fixed inset-0 z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } md:hidden`}
      ></div> */}
      <motion.div
        // variants={Nav_animation}
        // initial={{ width: open ? "2rem" : "4rem" }}
        animate={open ? "open" : "closed"}
        className="bg-white text-gray shadow-xl z-[999] fixed h-screen overflow-hidden"
      >
        <div className="flex items-center gap-2.5 font-medium border-b py-3 border-slate-300 mx-5">
          <img src={Logo} width={45} alt="Logo" />
          <span
            className={`text-xl whitespace-pre ${open ? "block" : "hidden"}`}
          >
            Cv Infotech
          </span>
        </div>

        <div className="flex flex-col h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] py-5 flex flex-col gap-1 font-medium overflow-y-auto">
            <li>
              <NavLink to={"/adminhome/admindashbord"} className="link">
                <FaHome size={25} className="min-w-max" />
                <span className={`ml-3 ${open ? "block" : "hidden"}`}>
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/adminhome/adminleaves"} className="link">
                <img src={Attendance} width={27} alt="Attendance" />
                <span className={`ml-3 ${open ? "block" : "hidden"}`}>
                  Leaves
                </span>
              </NavLink>
            </li>
           
            <li>
              <NavLink to={"/adminhome/adminattendance"} className="link">
                <img src={Attendance} width={27} alt="Attendance" />
                <span className={`ml-3 ${open ? "block" : "hidden"}`}>
                Attendance
                </span>
              </NavLink>
            </li>
            <li>
              <button onClick={() => setShowDialog(true)} className="link">
                <IoLogOut size={25} className="min-w-max" />
                <span className={`ml-3 ${open ? "block" : "hidden"}`}>
                  LogOut
                </span>
              </button>
            </li>
          </ul>
        </div>
        {/* <motion.div
          onClick={() => setOpen(!open)}
          animate={open ? { rotate: 0 } : { rotate: 180 }}
          transition={{ duration: 0.3 }}
          className="absolute w-fit h-fit right-2 bottom-3 cursor-pointer z-50"
        >
          <IoIosArrowBack size={25} />
        </motion.div> */}
      </motion.div>
      <div className="m-3 md:hidden" onClick={() => setOpen(true)}>
        <MdMenu size={25} />
      </div>
      {showDialog && (
        <div className="dialog-backdrop">
          <div className="dialog">
            <p>Alert!</p>
            <p>Are you sure you want to log out?</p>
            <div className="dialog-buttons">
              <button onClick={() => logout()} className="confirm-btn">
                Yes
              </button>
              <button
                onClick={() => setShowDialog(false)}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminSlider;
