import { AuthProvider, useAuth } from "./Component/Authentication/AuthContext";
import { Routes, Route, Navigate } from "react-router-dom";
import { LoginForm } from "./LoginForm/LoginForm";
import SliderRoutes from "./SliderRoutes";
import AdminSliderRoutes from "./Admin/AdminSliderRoutes";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import CustomLoader from "./Component/CustomLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Component/Authentication/ProtectedRoute";
import { persistor, store } from "./Redux/Store";

const AppContent = () => {
  const { loading } = useAuth();

  return (
    <>
      {loading && <CustomLoader />}
      <ToastContainer />
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/login" />} />
        <Route
          path="/home/*"
          element={<ProtectedRoute element={<SliderRoutes />} />}
        ></Route>
        <Route
          path="/adminhome/*"
          element={<ProtectedRoute element={<AdminSliderRoutes />} />}
        ></Route>
      </Routes>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <AppContent />

          {/* <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/home/*" element={<SliderRoutes />} />
          <Route path="/adminhome/*" element={<AdminSliderRoutes />} />

          
        </Routes> */}
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
