import Type from '../Redux/Constants'

export const setUserDetails = data => ({
    type: Type.EMPLOYEE_DETAILS,
    payload: data,
  });
  export const setEmployeeAuth = data => ({
    type: Type.EMPLOYEE_AUTH,
    payload: data,
  });
  export const setEmployeeLeaveDetails = data => ({
    type: Type.EMPLOYEE_LEAVE_DETAIL,
    payload: data,
  });