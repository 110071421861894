import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "../AdminComponent/AddEmployeeModal.css";
import { useAuth } from "../../Component/Authentication/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import { Api, BaseUrl } from "../../Config/Api";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Redux/Action";

const EditEmployeeModal = ({ open, onClose, user }) => {
  const { setLoading } = useAuth();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("authToken");
  const initialFormData = {
    name: "",
    employeeCode: "",
    email: "",
    contact: "",
    bloodgroup: "",
    location: "",
    designation: "",
    password: "",
    manager: "",
    dob: "",
    doj: "",
    don: "",
    pon:"",
    department: "",
    status: "",
    bio: "",
    image: null,
  };
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name,
        employeeCode: user.employee_code,
        email: user.email,
        contact: user.mobile,
        bloodgroup: user.blood_group,
        location: user.location,
        designation: user.designation,
        password: "12345",
        manager: user.reporting_manager,
        dob: user.dob,
        doj: user.doj,
        don: user.notice_period_end_date,
        department: user.department,
        experience: user.experience,
        status: user.status,
        bio: user.biometrics,
        image: null,
        pon:user.prohibition_end_date,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "image") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0] || null,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataObj = new FormData();

    Object.keys(formData).forEach((key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        formDataObj.append(key, formData[key]);
      }
    });

    if (formData.status === "Notice Period") {
      formDataObj.append("notice_period_end_date", formData.don);
    }
    if (formData.status === "Prohibition") {
      formDataObj.append("prohibition_end_date", formData.pon);
    }


    formDataObj.append("login_token", token);
    formDataObj.append("id", user.id);

    for (let [key, value] of formDataObj.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await axios.post(
        `${BaseUrl}${Api.UPDATE_EMPLOYEE_DETAILS}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toast.success(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
        fetchEmployees();
      } else {
        toast.error(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Failed to update employee:", error);
      toast.error("An error occurred while updating employee details.");
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_EMPLOYEE}?login_token=${token}`
      );

      if (
        responseData?.data?.mssg === "unothorized token please go to login page"
      ) {
        setLoading(false);
      } else {
        dispatch(setUserDetails(responseData?.data?.data));
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{ modal: "custom-modal" }}
    >
      <div
        style={{
          backgroundColor: "white",

          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "800px",
          width: "100%",
          margin: "auto",
          paddingRight: 30,
          paddingLeft: 30,
          paddingBottom: 20,
          paddingTop: 20,

          position: "relative",
        }}
      >
        <button
          onClick={() => {
            onClose();
            setFormData(initialFormData);
          }}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
            fontWeight: 700,
          }}
        >
          &times;
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: 30,
            paddingLeft: 100,
            fontWeight: 700,
          }}
        >
          <h2 style={{ marginBottom: "20px" }}>Edit Employee Details</h2>
        </div>
        <form onSubmit={handleSubmit} className="form-grid">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
              autoComplete="name"
            />
          </div>

          <div className="form-group">
            <label htmlFor="employeeCode">Employee Code:</label>
            <input
              type="text"
              name="employeeCode"
              id="employeeCode"
              value={formData.employeeCode}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email ID:</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              autoComplete="email"
            />
          </div>

          <div className="form-group">
            <label htmlFor="contact">Contact:</label>
            <input
              type="text"
              name="contact"
              id="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="bloodgroup">Blood Group:</label>
            <input
              type="text"
              name="bloodgroup"
              id="bloodgroup"
              value={formData.bloodgroup}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="location">Location:</label>
            <input
              type="text"
              name="location"
              id="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="designation">Designation:</label>
            <input
              type="text"
              name="designation"
              id="designation"
              value={formData.designation}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="experience">Experience:</label>

            <select
              name="experience"
              id="experience"
              value={formData.experience}
              onChange={handleChange}
              required
            >
              <option value="">Select Experience</option>
              <option value="Experience">Experience</option>
              <option value="Fresher">Fresher</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="manager">Reporting Manager:</label>
            <input
              type="text"
              name="manager"
              id="manager"
              value={formData.manager}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              name="dob"
              id="dob"
              value={formData.dob}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="doj">Date of Joining:</label>
            <input
              type="date"
              name="doj"
              id="doj"
              value={formData.doj}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="department">Department:</label>
            <select
              name="department"
              id="department"
              value={formData.department}
              onChange={handleChange}
              required
            >
              <option value="">Select Department</option>
              <option value="Web  Development">Web development</option>
              <option value="Apps Development">Apps Development</option>
              <option value="Designer">Designer</option>
              <option value="App Marketing">App Marketing</option>
              <option value="Seo">Seo</option>
              <option value="Project Coordinator">Project coordinator</option>
              <option value="Management">Management</option>
              <option value="Backend Development">Backend Development</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="status">Employee Status:</label>
            <select
              name="status"
              id="status"
              value={formData.status}
              onChange={handleChange}
              required
            >
              <option value="">Select Status</option>
              <option value="Prohibition">Prohibition</option>
              <option value="Full time">Full time</option>
              <option value="Notice Period">Notice Period</option>
              <option value="Intern">Intern</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>

          {formData.status == "Notice Period" && (
            <div className="form-group">
              <label htmlFor="don">Notice End Date:</label>
              <input
                type="date"
                name="don"
                id="don"
                value={formData.don}
                onChange={handleChange}
                required
              />
            </div>
          )}
          {formData.status == "Prohibition" && (
            <div className="form-group">
              <label htmlFor="pon">Prohibition End Date:</label>
              <input
                type="date"
                name="pon"
                id="pon"
                value={formData.pon}
                onChange={handleChange}
                required
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="bio">Biometric Code:</label>
            <input
              type="text"
              name="bio"
              id="bio"
              value={formData.bio}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="image">Employee Image:</label>
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleChange}
            />
          </div>
          <div className="button">
            <button type="submit" className="myButton2">
              Submit Details
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditEmployeeModal;
