import React from "react";
import "./Dashbord.css";
import backgroundImage from "../../Assets/empback.jpg";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import Animation from '../../Assets/Animation1.json'

const employee = {
  name: "John Doe",
  id: "EMP123",
  department: "Engineering",
  email: "john.doe@example.com",
  phone: "+123456789",
  image: "https://via.placeholder.com/100",
};

const Dashbord = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="mainDiv1">
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "300px",
        }}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
          marginTop: -120,
          // position: "absolute",

          width: "100%",
        }}
      >
        {/* Profile Picture */}
        <div style={{ flex: "0 0 200px" }}>
          <img
            src={employee.image}
            alt={`${employee.name} profile`}
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>

        <div style={{ flex: "1", marginLeft: "20px", marginTop: -20 }}>
          <h3
            style={{
              margin: "0 0 10px 0",
              fontSize: 30,
              color: "white",
              fontWeight: "700",
            }}
          >
            {employee.name}
          </h3>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          marginTop: -70,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",
            marginTop: 50,
            alignItems: "center",
          }}
        >
          Profile Details
        </h1>

        {/* <th>S.N</th>
             
                <th>Contact</th>
       
                <th>Location</th>
    
     
           
      
             
         
         
                <th>Notice End Date</th>
                <th>Prohibition End Date</th>
                <th>Biometric Code</th>
                <th>Password</th>
                <th>Action</th> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Email:</strong>{" "}
              <span style={{ color: "black" }}>{employee.email}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Employee ID:</strong>{" "}
              <span style={{ color: "black" }}>{employee.id}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Blood Group:</strong>{" "}
              <span style={{ color: "black" }}>{employee.id}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>DOB:</strong>{" "}
              <span style={{ color: "black" }}>{employee.id}</span>
            </p>
          </div>

          {/* Second Column */}
          <div style={{ flex: "1", paddingLeft: "10px" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Department:</strong>{" "}
              <span style={{ color: "black" }}>{employee.department}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Designation:</strong>{" "}
              <span style={{ color: "black" }}>{employee.phone}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Experience:</strong>{" "}
              <span style={{ color: "black" }}>{employee.status}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Date of Joining:</strong>{" "}
              <span style={{ color: "black" }}>{employee.status}</span>
            </p>
          </div>
          <div style={{ flex: "1", paddingLeft: "10px" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Reporting Manager:</strong>{" "}
              <span style={{ color: "black" }}>{employee.department}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Emp. Status:</strong>{" "}
              <span style={{ color: "black" }}>{employee.phone}</span>
            </p>

            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Biometric Code:</strong>{" "}
              <span style={{ color: "black" }}>{employee.status}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 20,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",

            alignItems: "center",
          }}
        >
          CONTACT DETAILS
        </h1>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Email:</strong>{" "}
              <span style={{ color: "black" }}>{employee.email}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Mobile No.:</strong>{" "}
              <span style={{ color: "black" }}>{employee.id}</span>
            </p>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Location:</strong>{" "}
              <span style={{ color: "black" }}>{employee.id}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 20,
          padding: "20px",
          background: "white",
          zIndex: -1,
          borderRadius: "8px", // Rounded corners for the card
        }}
      >
        <h1
          style={{
            fontSize: "24px",
            marginBottom: "20px",
            color: "black",

            alignItems: "center",
          }}
        >
          ABOUT ME
        </h1>
        {/* <Lottie options={defaultOptions}
              height={200}
              width={200}
          

              /> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ flex: "1" }}>
            <p style={{ fontSize: "20px" }}>
              <strong style={{ color: "#007BFF" }}>Departments:</strong>{" "}
              <span style={{ color: "black" }}>{employee.email}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashbord;
