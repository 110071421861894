import React, { useCallback, useEffect, useState } from "react";
import "./AdminDashbord.css";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AddEmployeeModal from "../AdminComponent/AddEmployeeModal";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { Api, BaseUrl } from "../../Config/Api";
import { setUserDetails } from "../../Redux/Action";
import { toast } from "react-toastify";
import EditEmployeeModal from "../AdminComponent/EditEmployeeModal";
import Lottie from "react-lottie";
import Animation from '../../Assets/Animation1.json'
const AdminDashbord = () => {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const getEmployeeDetails = useSelector((state) => state.getEmployeeDetails);
  const [employee, setEmployee] = useState();
  const token = sessionStorage.getItem("authToken");

  const [filteredCategories, setFilteredCategories] =
    useState(getEmployeeDetails);
  const { setLoading, logout } = useAuth();
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);
    updateFilteredCategories(event.target.value);
  };

  const updateFilteredCategories = (searchTerm) => {
    const lowerCaseQuery = searchTerm.trim().toLowerCase();

    const filteredItems = getEmployeeDetails.filter((item) => {
      return (
        item.name.toLowerCase().includes(lowerCaseQuery) ||
        item.employee_code.toLowerCase().includes(lowerCaseQuery) ||
        item.email.toLowerCase().includes(lowerCaseQuery)
      );
    });

    setFilteredCategories(filteredItems);
  };

  const handleClick = () => {
    // setOpen(true)
    setModalOpen(true);
  };

  const fetchEmployees = useCallback(async () => {
    setLoading(true);

    try {
      const responseData = await axios.get(
        `${BaseUrl}${Api.GET_EMPLOYEE}?login_token=${token}`
      );

      if (
        responseData?.data?.mssg === "unothorized token please go to login page"
      ) {
        setLoading(false);
        logout();
      } else {
        dispatch(setUserDetails(responseData?.data?.data));
        setFilteredCategories(responseData?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("API call failed:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [token, dispatch, setLoading, logout]);

  // const fetchEmployees = async () => {
  //   setLoading(true);

  //   try {
  //     const responseData = await axios.get(
  //       `${BaseUrl}${Api.GET_EMPLOYEE}?login_token=${token}`
  //     );

  //     if (
  //       responseData?.data?.mssg === "unothorized token please go to login page"
  //     ) {
  //       setLoading(false);
  //       logout();
  //     } else {
  //       dispatch(setUserDetails(responseData?.data?.data));
  //       setFilteredCategories(responseData?.data?.data);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("API call failed:", error);
  //     alert("An error occurred. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const deleteEmployeeDetails = async () => {
    setLoading(true);
    try {
      const response = await axios(`${BaseUrl}${Api.DELETE_EMPLOYEE}`, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          login_token: token,
          id: employee?.id,
        },
      });

      if (response?.data?.success === true) {
        toast.success(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
        fetchEmployees();
        setShowDialog(false)
      } else {
        toast.error(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
        setShowDialog(false)
      }
    } catch (error) {
      console.error("API call failed:", error);
      alert("An error occurred. Please try again.");
      setShowDialog(false)
    } finally {
      setLoading(false);
      setShowDialog(false)
    }
  };
  const editEmployeeDetails = (empData) => {
    setEmployee(empData);
    setEditModalOpen(true);
  };
  useEffect(() => {
    fetchEmployees();
  }, [dispatch, token, modalOpen, editmodalOpen]);
  return (
    <div className="mainDiv">
      <div className="crow">
        <div className="crow1">
          <h1 style={{ fontWeight: "700", fontSize: 30, color: "black" }}>
            Employee
          </h1>
          <div style={{ paddingLeft: 10 }}>
            <h1 style={{ fontWeight: "400", fontSize: 20, color: "gray" }}>
              ({getEmployeeDetails?.length})
            </h1>
          </div>
        </div>
        <div className="button-container">
          <button className="myButton" onClick={handleClick}>
            <FaPlus style={{ marginRight: "10px" }} />
            Add Employee
          </button>
        </div>
      </div>

      <div className="searchBar-container">
        <div className="searchBar-container1">
          <input
            type="text"
            id="search-query"
            name="query"
            value={query}
            onChange={handleInputChange}
            placeholder="Search..."
            className="searchBar-input"
            autoComplete="current-query"
          />
          <button className="searchBar-button">Search</button>
        </div>
      </div>

      <div className="card-container">
        <div className="table-container">
          <table className="employee-table">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Name</th>
                <th>Emp. Code</th>
                <th>Email ID</th>
                <th>Contact</th>
                <th>BG</th>
                <th>Location</th>
                <th>Designation</th>
                <th>Experience</th>
                <th>Date of Birth</th>
                <th>Date of Joining</th>
                <th>Department</th>
                <th>Reporting Manager</th>
                <th>Emp. Status</th>
                <th>Notice End Date</th>
                <th>Prohibition End Date</th>
                <th>Biometric Code</th>
                <th>Password</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCategories.map((emp, index) => (
                <tr key={emp.id}>
                  <td>{index + 1}</td>
                  <td>{emp.name}</td>
                  <td>{emp.employee_code}</td>
                  <td>{emp.email}</td>
                  <td>{emp.mobile}</td>
                  <td>{emp.blood_group}</td>
                  <td>{emp.location}</td>
                  <td>{emp.designation}</td>
                  <td>{emp.experience}</td>
                  <td>{emp.dob}</td>
                  <td>{emp.doj}</td>
                  <td>{emp.department}</td>
                  <td>{emp.reporting_manager}</td>
                  <td>{emp.status}</td>
                  <td>{emp.notice_period_end_date}</td>
                  <td>{emp.prohibition_end_date}</td>
                  <td>{emp.biometrics}</td>
                  <td>12345</td>
                  <td>
                    <button
                      className="action-button"
                      onClick={() => {
                        editEmployeeDetails(emp);
                      }}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="action-button"
                      onClick={() => {
                        setEmployee(emp);
                        // deleteEmployeeDetails(emp);
                        setShowDialog(true)
                      }}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showDialog && (
        <div className="dialog-backdrop">
          <div className="dialog">
            <p>Alert!</p>
            <p>Are you sure you want to delete file?</p>
              <Lottie options={defaultOptions}
              height={200}
              width={200}
          

              />
            <div className="dialog-buttons">
              <button onClick={() => deleteEmployeeDetails()} className="confirm-btn">
                Yes
              </button>
              <button
                onClick={() => setShowDialog(false)}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <AddEmployeeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        // user={employees}
        // onSave={handleSave}
      />
      <EditEmployeeModal
        open={editmodalOpen}
        onClose={() => setEditModalOpen(false)}
        user={employee}
      />
    </div>
  );
};

export default AdminDashbord;
