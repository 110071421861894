export const BaseUrl="https://hrmanagement.cvinfotechserver.com/cvi/public/api/";
export const ImagePath='https://hrmanagement.cvinfotechserver.com/cvi/public/images/';
export const Api={
    //Admin Api End   Points
    LOGIN:'login',
    ADD_EMPLOYEE_DETAILS:'add_employee',
    GET_EMPLOYEE:'get_users',
    DELETE_EMPLOYEE:'delete_employee',
    UPDATE_EMPLOYEE_DETAILS:'update_employee',
    ADD_LEAVE:'add_leave',
    GET_EMPLOYEE_LEAVE_DETAILS:'get_user_leave_data',
    UPDATE_EMPLOYEE_LEAVE:'update_add_leave'


}