import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-responsive-modal";
import { useAuth } from "../../Component/Authentication/AuthContext";
import axios from "axios";
import { Api, BaseUrl } from "../../Config/Api";
import { toast } from "react-toastify";
import { setEmployeeLeaveDetails } from "../../Redux/Action";

const EditLeaveStatusModal = ({ open, onClose, user, leave }) => {
  const { setLoading, logout } = useAuth();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("authToken");
  const [emptyFields, setEmptyFields] = useState([]);

  const initialFormData = {
    leave_name: "",
    leave_code: "",
    leave_status: "",
    leave_type: "",

    leave_unit: "days",
    leave_description: "",
    leave_start_date: "",
    leave_end_date: "",
    leave_start_datetime: "",
    leave_end_datetime: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  console.log("XCvcxvcxvcx", leave);
  useEffect(() => {
    if (user) {
      setFormData({
        leave_name: leave?.leave_name,
        leave_code: leave?.leave_code,
        leave_status: leave?.leave_status,
        leave_type: leave?.leave_type,

        leave_unit: leave?.leave_period,
        leave_description: leave?.resone,
        leave_start_date: leave?.leave_start_date,
        leave_end_date: leave?.leave_end_date,
        leave_start_datetime: leave?.leave_start_date,
        leave_end_datetime: leave?.leave_end_date,
      });
    }
  }, [user]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (value.trim() !== "") {
      setEmptyFields(emptyFields.filter((field) => field !== name));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== undefined && formData[key] !== null) {
        formDataObj.append(key, formData[key]);
      }
    });

    if (formData.leave_unit === "half-day") {
      if (!formData.leave_start_datetime || !formData.leave_end_datetime) {
        alert("Please fill in the Start and End Date/Time for Half Day.");
        return;
      }
    } else {
      if (!formData.leave_start_date || !formData.leave_end_date) {
        alert("Please fill in the Start and End Date for Full Days.");
        return;
      }
    }
    formDataObj.append("login_token", token);
    formDataObj.append("id", user.id);
    formDataObj.append("employee_id", user.id);
    // if (empty.length > 0) {
    //   alert(`Please fill in the following fields: ${empty.join(", ")}`);
    //   return;
    // }

    setLoading(true);

    // try {
    //   const formDataToSend = new FormData();
    //   formDataToSend.append("leave_name", formData.leave_name);
    //   formDataToSend.append("leave_code", formData.leave_code);
    //   formDataToSend.append("leave_type", formData.leave_type);
    //   formDataToSend.append("leave_status", formData.leave_status);
    //   formDataToSend.append("leave_period", formData.leave_unit);
    //   formDataToSend.append("reasone", formData.leave_description);
    //   formDataToSend.append("id", user.id);
    //   formDataToSend.append("login_token", token);

    //   if (formData.leave_unit === "half-day") {
    //     formDataToSend.append(
    //       "leave_start_date",
    //       formData.leave_start_datetime
    //     );
    //     formDataToSend.append("leave_end_date", formData.leave_end_datetime);
    //   } else {
    //     formDataToSend.append("leave_start_date", formData.leave_start_date);
    //     formDataToSend.append("leave_end_date", formData.leave_end_date);
    //   }

    //   const response = await axios.post(
    //     `${BaseUrl}${Api.ADD_LEAVE}`,
    //     formDataToSend,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );

    //   if (
    //     response?.data?.mssg === "unothorized token please go to login page"
    //   ) {
    //     logout();
    //   } else {
    //     if (response?.data?.success === true) {
    //       toast.success(response?.data?.mssg, {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });

    //       //   getEmployeeLeavdDetails();
    //       //   setFormData(initialFormData);
    //     } else if (response?.data?.success === false) {
    //       toast.error(response?.data?.mssg, {
    //         position: "top-center",
    //         autoClose: 1000,
    //       });
    //     }
    //   }
    // } catch (error) {
    //   console.error("API call failed:", error);
    //   alert("An error occurred. Please try again.");
    // } finally {
    //   setLoading(false);
    // }
    try {
      const response = await axios.post(
        `${BaseUrl}${Api.UPDATE_EMPLOYEE_LEAVE}`,
        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data?.success) {
        toast.success(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
        // getEmployeeLeavdDetails()
      } else {
        toast.error(response?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Failed to update employee:", error);
      toast.error("An error occurred while updating employee details.");
    } finally {
      setLoading(false);
    }
  };
  // const getEmployeeLeavdDetails = async (data) => {
  //   setLoading(true);

  //   try {
  //     const responseData = await axios.get(
  //       `${BaseUrl}${Api.GET_EMPLOYEE_LEAVE_DETAILS}?login_token=${token}&id=${getEmployeeDetails[data]?.id}`
  //     );

  //     if (
  //       responseData?.data?.mssg === "unothorized token please go to login page"
  //     ) {
  //       setLoading(false);
  //       logout();
  //     } else {
  //       console.log("Rakesh Rao",responseData.data)
  //       dispatch(setEmployeeLeaveDetails(responseData?.data));

  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("API call failed:", error);
  //     toast.error("An error occurred. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{ modal: "custom-modal" }}
    >
      <div
        style={{
          backgroundColor: "white",

          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "800px",
          width: "100%",
          margin: "auto",
          paddingRight: 30,
          paddingLeft: 30,
          paddingBottom: 20,
          paddingTop: 20,

          position: "relative",
        }}
      >
        <button
          onClick={() => {
            onClose();
            setFormData(initialFormData);
          }}
          style={{
            position: "absolute",
            top: "0px",
            right: "10px",
            backgroundColor: "transparent",
            border: "none",
            fontSize: "30px",
            cursor: "pointer",
            fontWeight: 700,
          }}
        >
          &times;
        </button>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            fontSize: 30,
            // paddingLeft: 100,
            fontWeight: 700,
          }}
        >
          <h2 style={{ marginBottom: "20px" }}>Edit Employee Leave Details</h2>
        </div>
        <form onSubmit={handleSubmit} className="form-grid1">
          <div className="form-group1">
            <label htmlFor="status">Employee Status:</label>
            <input
              type="text"
              name="status"
              id="status"
              value={user.status}
              readOnly
              // onChange={handleChange}
              // required
            />
          </div>
          <div className="form-group1">
            <label htmlFor="leave_name">Leave Name:</label>
            <input
              type="text"
              name="leave_name"
              id="leave_name"
              value={formData.leave_name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group1">
            <label htmlFor="leave_code">Leave Code:</label>
            <select
              name="leave_code"
              id="leave_code"
              value={formData.leave_code}
              onChange={handleChange}
              required
            >
              <option value="">Select Code</option>
              <option value="SL">SL</option>
              <option value="CL">CL</option>
            </select>
          </div>
          <div className="form-group1">
            <label htmlFor="leave_type">Leave Type:</label>

            <select
              name="leave_type"
              id="leave_type"
              value={formData.leave_type}
              onChange={handleChange}
              required
              //   value={
              //     user.status === "Prohibition" ? "Unpaid" : formData.leave_type
              //   }
              //   disabled={user.status === "Prohibition"}
            >
              <option value="">Select Type</option>
              <option value="Paid">Paid</option>
              <option value="Unpaid">Unpaid</option>
            </select>
          </div>
          <div className="form-group1">
            <label htmlFor="leave_status">Leave status:</label>
            <select
              name="leave_status"
              id="leave_status"
              value={formData.leave_status}
              onChange={handleChange}
              required
            >
              <option value="">Select Status</option>
              <option value="Approved">Approved</option>
              <option value="Unapproved">Unapproved</option>
            </select>
          </div>

          <div className="form-group1">
            <label htmlFor="leave_unit">Unit:</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="leave_unit" // Updated name attribute to match the first radio button
                  value="days"
                  checked={formData.leave_unit === "days"}
                  onChange={handleChange}
                  required
                />
                Days
              </label>
              <label>
                <input
                  type="radio"
                  name="leave_unit" // Updated name attribute to match the first radio button
                  value="half-day"
                  checked={formData.leave_unit === "half-day"}
                  onChange={handleChange}
                  required
                />
                Half Day
              </label>
            </div>
          </div>

          <div className="form-group1">
            <label htmlFor="leave_description">Description:</label>
            <textarea
              name="leave_description"
              id="leave_description"
              value={formData.leave_description}
              onChange={handleChange}
              required
              rows="4"
            />
          </div>

          {formData?.leave_unit === "days" ? (
            <div className="form-group1 validity-group">
              <label htmlFor="validity">Validity:</label>
              <div className="date-group">
                <div className="date-field">
                  <label htmlFor="leave_start_date">Start Date:</label>
                  <input
                    type="date"
                    name="leave_start_date"
                    id="leave_start_date"
                    value={formData.leave_start_date}
                    onChange={handleChange}
                  />
                </div>
                <div className="date-field">
                  <label htmlFor="leave_end_date">End Date:</label>
                  <input
                    type="date"
                    name="leave_end_date"
                    id="leave_end_date"
                    value={formData.leave_end_date}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="form-group1 validity-group">
              <label htmlFor="validity">Validity:</label>
              <div className="date-group">
                <div className="date-field">
                  <label htmlFor="leave_start_datetime">
                    Start Date and Time:
                  </label>
                  <input
                    type="datetime-local"
                    name="leave_start_datetime"
                    id="leave_start_datetime"
                    value={formData.leave_start_datetime}
                    onChange={handleChange}
                  />
                </div>
                <div className="date-field">
                  <label htmlFor="leave_end_datetime">End Date and Time:</label>
                  <input
                    type="datetime-local"
                    name="leave_end_datetime"
                    id="leave_end_datetime"
                    value={formData.leave_end_datetime}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="button">
            <button type="submit" className="myButton2">
              Submit Details
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditLeaveStatusModal;
