import Type from "../Redux/Constants";
const intialState = {
  getEmployeeDetails: [],
  getEmployeeAuth: false,
  getEmployeeLeaveDetails:[]
};
const EmployeeReduser = (state = intialState, action) => {
  switch (action.type) {
    case Type.EMPLOYEE_DETAILS:
      return { ...state, getEmployeeDetails: action.payload };
    case Type.EMPLOYEE_AUTH:
      return { ...state, getEmployeeAuth: action.payload };
    case Type.EMPLOYEE_LEAVE_DETAIL:
      return { ...state, getEmployeeLeaveDetails: action.payload };
    default:
      return state;
  }
};
export default EmployeeReduser;
