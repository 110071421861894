import React from "react";
import AdminRootLayout from "./AdminRootLayout";
import { Route, Routes } from "react-router-dom";

import AdminDashbord from "./AdminPage/AdminDashbord";
import AdminLeaves from "./AdminPage/AdminLeaves";
import Attendance from "./AdminPage/Attendance";

const AdminSliderRoutes = () => {
  return (
    <AdminRootLayout>
      <Routes>
        <Route path="/admindashbord" element={<AdminDashbord />} />
        <Route path="/*" element={<AdminDashbord />} />
        <Route path="/" element={<AdminDashbord />} />
        <Route path="/adminleaves" element={<AdminLeaves />} />
        <Route path="/adminattendance" element={<Attendance />} />
      </Routes>
    </AdminRootLayout>
  );
};

export default AdminSliderRoutes;
