import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl, Api } from "../../Config/Api";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeAuth } from "../../Redux/Action";
import { persistor } from "../../Redux/Store";
const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  const getEmployeeAuth = useSelector((state) => state.getEmployeeAuth);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("authToken");

    if (token) {
      dispatch(setEmployeeAuth(true));
      //  setIsAuthenticated(true);
    } else {
      navigate("/login");
    }
    setLoading(false);
  }, [navigate, dispatch]);

  const login = async (email, password, selection) => {
    setLoading(true);
    try {
      const data = await axios(`${BaseUrl}${Api.LOGIN}`, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          email: email,
          password: password,
          type: selection,
        },
      });
     
      if (data?.data?.mssg === "Login successfully") {
        // setLoading(false);
        toast.success(data?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
  

        if (data?.data?.type === "Employee") {
         
          const token = data?.data?.token;
          sessionStorage.setItem("authToken", token);
          dispatch(setEmployeeAuth(true));
          navigate("/home/dashbord");
     
        } else {
          const token = data?.data?.token;
          sessionStorage.setItem("authToken", token);
          dispatch(setEmployeeAuth(true));
          console.log("ZXzvxzvcxvcxv",data?.data)

          navigate("/adminhome/admindashbord");
        }
      } else {
        // setLoading(false);
        toast.error(data?.data?.mssg, {
          position: "top-center",
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(error, {
        position: "top-center",
        autoClose: 1000,
      });
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    dispatch(setEmployeeAuth(false));
    sessionStorage.removeItem("authToken");
    await persistor.flush();
    await persistor.purge();
    window.location.reload();
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{ getEmployeeAuth, login, logout, loading, setLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
