import EmployeeSlider from "./EmployeeSlider";

import "../Employee/RootLayout.css";
function RootLayout({ children }) {
  return (
    <div className="mainContainer">
      <div className="crow">
        <div className="sub">
          <EmployeeSlider />
        </div>
        <div className="sub1">{children}</div>
      </div>
    </div>
  );
}

export default RootLayout;
